import { Outlet } from 'react-router-dom';



const MinimalLayout = () => (
  <>
    <Outlet /> 
  </>
);

export default MinimalLayout;
